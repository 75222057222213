import BreadCrumpWrapper from "../components/BreadCrumpWrapper";
import { useContext } from "react";
import { ProductsContext } from "../contexts/ProductsContext";
import DeliveryDetails from "../components/checkoutComponents/DeliveryDetails";

import "./Checkout.css";
import { useLocation } from "react-router-dom";
const Checkout = () => {
  const { cartList, removeFromCart } = useContext(ProductsContext);
  let checkoutTotal = 0;
  cartList.forEach((item) => {
    checkoutTotal += item.quantity * item.pricing[item.package];
  });

  const location = useLocation();
  console.log(window.location.origin);
  console.log(cartList);
  return (
    <main>
      <article>
        <BreadCrumpWrapper
          pageTitle={"Enjoy every cup!"}
          id="checkout-bread"
          pageUrl="checkout"
        />
        <section className="section checkout">
          <div className="container checkout-container">
            <div className="checkout-box checkout-cart">
              {cartList?.length === 0 && (
                <div style={{ color: "green" }}>
                  Your cart is empty. Go shopping.
                </div>
              )}
              {cartList.map((cartItem) => (
                <div
                  key={Math.floor(Math.random() * 1000) + Date.now()}
                  className="checkout-item"
                >
                  <figure>
                    <img
                      src={`${window.location.origin}/assets/images/${cartItem.image}`}
                      width="80"
                      height="80"
                      alt={cartItem.productName}
                      loading="lazy"
                    />
                  </figure>
                  <div>
                    <h3>{cartItem.productName}</h3>
                    <p>
                      {cartItem.package}- KSh.
                      {cartItem.pricing[cartItem.package]}x{cartItem.quantity}
                    </p>
                    <p>
                      SubTotal:{" "}
                      {cartItem.pricing[cartItem.package] * cartItem.quantity}
                    </p>
                  </div>
                  <div
                    className="chekout-remove-item-btn"
                    onClick={() =>
                      removeFromCart(cartItem.productId, cartItem.package)
                    }
                  >
                    <ion-icon name="close-outline"></ion-icon>
                  </div>
                </div>
              ))}
            </div>
            <DeliveryDetails checkoutTotal={checkoutTotal} />
          </div>
        </section>
      </article>
    </main>
  );
};

export default Checkout;
