import React from 'react'
import Logistics from '../components/aboutComponents/Logistics'
import shipping from "../images/shiping.jpg"
import BreadCrumpWrapper from "../components/BreadCrumpWrapper"

const Shipping = () => {
  return (
    <main>
    <article>
      <BreadCrumpWrapper pageTitle={"Sourcing and Shipping Logistics"} id="shipping-bread" pageUrl="Shipping & Logistics" />
      <Logistics />
        <section className="section offers">
          <div className="container">
              <div className="right-image-section">
                  <div className="section-text-wrapper">
                  <p className="p" style={{fontWeight: "bold"}}>
                  Trading contract terms and conditions mostly applied are:-
                </p>
                <ol className="p" style={{listStyleType: "upper-roman"}}>
                    <li>a) An irrevocable Letter of Credit ( Then draw contracts with equivalent gross value)</li>
                    <li>b) FOB prices at port of Mombasa.</li>
                    <li>c) FCA at bollore logistics company warehouse Nairobi or any other logistics and forwarding company as advised by our clients.</li>
                    <li>d) CIF prices at destination port.</li>
                    <li>e) DHL Express (offices) or Cargo at JKIA Nairobi</li>
                </ol>
                </div>
                  <div className="section-image-wrapper">
                  <img className="section-image" src={shipping} alt="Shipping Containers" />
                  <p className="image-description">Our MD seeing off a shipment</p>
                  </div>
              </div>
            </div>
        </section>
        
          <section className="section offers">
          <div className="container">
              <div className="p">
              Prices are negotiated based on product quality, demand and supply as dictated by the prevailing market prices. But most importantly is building strong and long-term trading partner relationship on basis of win-win strategy. We are looking forward to doing business with you.
                </div>
            </div>
        </section>

    </article>
    </main>
  )
}

export default Shipping