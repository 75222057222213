import React from 'react'
import "./LeftImageSection.css"

const LeftImageSection = ({sectionTitle, sectionText, sectionImage, imageDescription}) => {
  return (
    <section className="section offers">
        <div className="container">
            <h3 className="section-title h3 left-image-section-title" >{sectionTitle}</h3>
            <div className="left-image-section">
                <div className="section-image-wrapper">
                    <img className="section-image" src={`./assets/images/${sectionImage}`} alt={imageDescription} />
                    <p className="image-description">{imageDescription}</p>
                </div>
                <p className="p">{sectionText}</p>
                
            </div>
        </div>
    </section>
  )
}

export default LeftImageSection