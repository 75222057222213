import {useContext} from 'react'
import { ProductsContext } from '../../contexts/ProductsContext'
import ProductCard from "../../components/ProductCard"



const TeaList = () => {
  const {productsList, addToCartClickHandler} = useContext(ProductsContext)
  const mainTeaList = productsList.filter(product => product.category==="tea")
  return (
    <section className="section product">
        <div className="container">
          <p className="section-subtitle"> --  Peaceful, Natural, and Aromatic. --</p>

          <h2 className="h2 section-title">Our Tea</h2>

          <ul className="grid-list">

            {
              mainTeaList.map(product=>{
                return(
                  <ProductCard key={product.productId}  id={product.productId} addToCartClickHandler={addToCartClickHandler} />
                )
              })
            }
          </ul>
         
        </div>
      </section>
  )
}

export default TeaList