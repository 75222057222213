import {Link} from 'react-router-dom'
import BreadCrumpWrapper from '../components/BreadCrumpWrapper'
import "./ErrorPage.css"

const ErrorPage = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
  return (
    <main>
      <article>
        <BreadCrumpWrapper  pageTitle="OOPs!!! Sorry. Page Not Found." pageUrl="404 Error" id="error-bread"  />
        <div className="container error-page">
            <p className="p">Help us improve our services by sending a bug report. Its simple!!. Just click the button below</p>
            <div>
                <button className="btn">Send Bug Report</button>
            </div>
            <p className="p">We will fix this. Thank you.</p>
            <div>
                <Link to="/shop" onClick={()=>scrollToTop()} >Continue Shopping ...</Link>
            </div>
        </div>
      </article>
    </main>
  )
}

export default ErrorPage