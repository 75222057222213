import {useContext} from "react"
import {ProductsContext} from "../../contexts/ProductsContext"
import ProductCard from "../../components/ProductCard"

const CoffeeList = () => {
  const {productsList} = useContext(ProductsContext)
  const mainCoffeeList = productsList.filter(product => product.category==="coffee")

  return (
    <section className="section product" id="cofee-list">
        <div className="container">
          <p className="section-subtitle"> --    A cup of coffee for every mood  --</p>
          <h2 className="h2 section-title">Our Coffee</h2>

          <ul className="grid-list has-scroll">
            {
              mainCoffeeList.map(product=>{
                return(
                  <ProductCard key={product.productId} id={product.productId} />
                )
              })
            }
          
          </ul>

        </div>
      </section>
  )
}

export default CoffeeList