const allProductsList = [
    {
        productId: 1,
        category: "coffee",
        productName: "Roasted Coffee Beans",
        grade: "AA",
        pricing: {
            "240grams":600,
            "500grams": 1200,
            "1Kg": 2400,
        },
        image: "roasted-ground-coffee.jpg",
        qualityCupScore: "86%-90%",
        description: "Our coffee beans are traceable from tree to cup. Coffee Cherries are delivered at the wet mills, sorted, pulped, fermented, washed and separated in various parchment grades and dried as lots. Every lot of parchment grades consignment are delivered to the dry coffee mills for hulling and grades separations are issued with outturn number which is unique. ",
        healthBenefits: [
            "Boosts energy levels",
            "May be linked to a lower risk of type 2 diabetes",
            "Could support brain health",
            "Supports heart health"
        ],

    }, 
    {
        productId: 2,
        category: "coffee",
        productName: "Ground Roasted Coffee",
        grade: "AA",
        pricing: {
            "240grams":600,
            "500grams": 1200,
            "1Kg": 2400,
        },
        qualityCupScore: "86%-90%",
        image: "roasted-ground-coffee.jpg",
        description: "Specialty Arabica green coffee beans are of high premium and unique quality attributes. Our various green bean grades are easily distinguished depending on their cup profiles, cup scores, production profile, regions’s soil, weather and altitude above sea level. Members including women and youth benefit directly from our methods of processing during harvest period.",
        healthBenefits: [
            "May enhance athletic performance",
            "May be linked to a lower risk of type 2 diabetes",
            "Could support brain health",
            "You could live longer"
        ],

        
    },
    {
        productId: 3,
        category: "coffee",
        productName: "Fully Washed Green Coffee Beans",
        grade: "AA",
        pricing: {
            "1Kg": 1580
        },
        qualityCupScore: "86% - 90%",
        image: "fully-washed-AA.jpg",
        description: "Our farmers’ source their inputs from licensed agroinputs supply chains and keep records of inputs bought and used. The farmers’ works in collaboration with Coffee Research Institute and other licensed organization by the Government for their soil and leaf analysis and are advised appropriately on nutrition, pests and diseases control measures to undertake",
        healthBenefits: [
            "May enhance athletic performance",
            "May be linked to a lower risk of type 2 diabetes",
            "Could support brain health",
            "You could live longer"
        ],

    },
    {
        productId: 4,
        category: "coffee",
        productName: "Fully Washed Green Coffee Beans",
        grade: "AB",
        pricing: {
            "1Kg": 1280,
        },
        qualityCupScore: "85% - 88%",
        image: "fully-washed-AB.jpg",
        description: "Our farmers’ source their inputs from licensed agroinputs supply chains and keep records of inputs bought and used. The farmers’ works in collaboration with Coffee Research Institute and other licensed organization by the Government for their soil and leaf analysis and are advised appropriately on nutrition, pests and diseases control measures to undertake",
        healthBenefits: [
            "May enhance athletic performance",
            "May be linked to a lower risk of type 2 diabetes",
            "Could support brain health",
            "You could live longer"
        ],
    },
    {
        productId: 5,
        category: "coffee",
        productName: "Fully Washed Green Coffee Beans",
        grade: "PB",
        // quantities: [240,500, 1000],
        pricing:{
            "1Kg": 1430
        } ,
        
        qualityCupScore: "85% - 88.50%",
        image: "fully-washed-PB.jpg",
        description: "Our farmers’ source their inputs from licensed agroinputs supply chains and keep records of inputs bought and used. The farmers’ works in collaboration with Coffee Research Institute and other licensed organization by the Government for their soil and leaf analysis and are advised appropriately on nutrition, pests and diseases control measures to undertake",
        healthBenefits: [
            "May enhance athletic performance",
            "May be linked to a lower risk of type 2 diabetes",
            "Could support brain health",
            "You could live longer"
        ],
    },
    {
        productId: 6,
        category: "coffee",
        productName: "Fully Washed Green Coffee Beans",
        grade: "C",
        pricing: {
            "1Kg": 990,
        },
        qualityCupScore: "82.50% - 84.25%",
        image: "fully-washed-C.jpg",
        description: "Our farmers’ source their inputs from licensed agroinputs supply chains and keep records of inputs bought and used. The farmers’ works in collaboration with Coffee Research Institute and other licensed organization by the Government for their soil and leaf analysis and are advised appropriately on nutrition, pests and diseases control measures to undertake",
        healthBenefits: [
            "May enhance athletic performance",
            "May be linked to a lower risk of type 2 diabetes",
            "Could support brain health",
            "You could live longer"
        ],
    },
    {
        productId: 7,
        category: "coffee",
        productName: "Red Cherry coffee - Natural dried process- Green beans ",
        grade: "Screen +15",
        pricing: {
            "1Kg": 1810,
        },
        qualityCupScore: "87% - 92.5%",
        image: "red-cherry.jpg",
        description: "Our farmers’ source their inputs from licensed agroinputs supply chains and keep records of inputs bought and used. The farmers’ works in collaboration with Coffee Research Institute and other licensed organization by the Government for their soil and leaf analysis and are advised appropriately on nutrition, pests and diseases control measures to undertake",
        healthBenefits: [
            "May enhance athletic performance",
            "May be linked to a lower risk of type 2 diabetes",
            "Could support brain health",
            "You could live longer"
        ],
    },
    {
        productId: 8,
        category: "coffee",
        productName: "Honey Processed Beans",
        grade: "Screen +15",
        pricing: {
            "1Kg": 1793,
        },
        qualityCupScore: "87% - 92.5%",
        image: "honey-processed.jpg",
        description: "Our farmers’ source their inputs from licensed agroinputs supply chains and keep records of inputs bought and used. The farmers’ works in collaboration with Coffee Research Institute and other licensed organization by the Government for their soil and leaf analysis and are advised appropriately on nutrition, pests and diseases control measures to undertake",
        healthBenefits: [
            "May enhance athletic performance",
            "May be linked to a lower risk of type 2 diabetes",
            "Could support brain health",
            "You could live longer"
        ],
        
    },
    {
        productId: 9,
        category: "tea",
        productName: "CTC Black loose-tea",
        grade: "BP1",
        quantities: [100,240,500],
        pricePerKg:  800,
        pricing: {
            "100g": 90,
            "240g": 200,
            "500g": 400,
            "1Kg": 750,
        },
        image: "ctc-black-tea.jpg",
        description: "Our CTC black tea primary grades offers our tea lovers with their anticipated tastes and preference notwithstanding, rich body, good mouth feel & aftertaste, pleasing and appealing fragrance, aroma and distinctive flavors.",
        healthBenefits: [
            "Antioxidants, including catechins & polyphenols",
            "Gut and Heart health",
            "Improved metabolism ",
            "Possible anti-cancer effects"
        ],
    },
    {
        productId: 10,
        category: "tea",
        productName: "White Orthodox-tea",
        grade: "Silver",
        pricing:{
            "40g": 1730
        },
        // pricePerKg:  800,
        image: "white-orthodox-silver.jpg",
        description: "Our orthodox tea are processed from ordinary tea bushes. They are processed by rolling the leaf whole without fermenting during production. From the cup brews, our customers will enjoys lovely apples and caramel fragrance and aroma, full bodied, with soft, sweet with smooth appealing taste.",
        healthBenefits: [
            "Rich in anti-oxidants",
            "Could help you loose weight",
            "May lower risk of insulin resistance",
            "May protect the brain from aging "
        ]
    },
    {
        productId: 11,
        category: "tea",
        productName: "White Orthodox-tea",
        grade: "Premium",
        pricing: {
            "40g": 1250
        },
        image: "white-orthodox-premium.jpg",
        description: "Our orthodox tea are processed from ordinary tea bushes. They are processed by rolling the leaf whole without fermenting during production. From the cup brews, our customers will enjoys lovely apples and caramel fragrance and aroma, full bodied, with soft, sweet with smooth appealing taste.",
        healthBenefits: [
            "Rich in anti-oxidants",
            "Could help you loose weight",
            "May lower risk of insulin resistance",
            "May protect the brain from aging "
        ]
    },
    {
        productId: 12,
        category: "tea",
        productName: "Green Orthodox-tea",
        grade: "BOP",
        pricing: {
            "100g": 300
        },
        image: "green-tea-BOP.jpg",
        description: "Our orthodox tea are processed from ordinary tea bushes. They are processed by rolling the leaf whole without fermenting during production. From the cup brews, our customers will enjoys lovely apples and caramel fragrance and aroma, full bodied, with soft, sweet with smooth appealing taste.",
        healthBenefits: [
            "Rich in anti-oxidants",
            "Could help you loose weight",
            "May lower risk of insulin resistance",
            "May protect the brain from aging "
        ]
    },
    {
        productId: 13,
        category: "tea",
        productName: "Green Orthodox-tea",
        grade: "BOP1",
        pricing: {
            "100g": 300
        },
        image: "green-tea-BOP1.jpg",
        description: "Our orthodox tea are processed from ordinary tea bushes. They are processed by rolling the leaf whole without fermenting during production. From the cup brews, our customers will enjoys lovely apples and caramel fragrance and aroma, full bodied, with soft, sweet with smooth appealing taste.",
        healthBenefits: [
            "Rich in anti-oxidants",
            "Could help you loose weight",
            "May lower risk of insulin resistance",
            "May protect the brain from aging "
        ]
    },
    {
        productId: 14,
        category: "tea",
        productName: "Black Orthodox-tea",
        grade: "FOP",
        pricing: {
            "100g": 300
        },
        image: "black-orthodox-FOP.jpg",
        description: "Our orthodox tea are processed from ordinary tea bushes. They are processed by rolling the leaf whole without fermenting during production. From the cup brews, our customers will enjoys lovely apples and caramel fragrance and aroma, full bodied, with soft, sweet with smooth appealing taste.",
        healthBenefits: [
            "Rich in anti-oxidants",
            "Could help you loose weight",
            "May lower risk of insulin resistance",
            "May protect the brain from aging "
        ]
    },
    {
        productId: 15,
        category: "tea",
        productName: "Black Orthodox-tea",
        grade: "OPA",
        pricing: {
            "100g": 300
        },
        image: "black-orthodox-OPA.jpg",
        description: "Our orthodox tea are processed from ordinary tea bushes. They are processed by rolling the leaf whole without fermenting during production. From the cup brews, our customers will enjoys lovely apples and caramel fragrance and aroma, full bodied, with soft, sweet with smooth appealing taste.",
        healthBenefits: [
            "Rich in anti-oxidants",
            "Could help you loose weight",
            "May lower risk of insulin resistance",
            "May protect the brain from aging "
        ]
    },
    {
        productId: 16,
        category: "tea",
        productName: "Purple Orthodox-tea",
        grade: "Whole rolled leaf",
        pricing: {
            "100g": 300
        },
        image: "purple-orthodox.jpg",
        description: "Our orthodox tea are processed from ordinary tea bushes. They are processed by rolling the leaf whole without fermenting during production. From the cup brews, our customers will enjoys lovely apples and caramel fragrance and aroma, full bodied, with soft, sweet with smooth appealing taste.",
        healthBenefits: [
            "Rich in anti-oxidants",
            "Could help you loose weight",
            "May lower risk of insulin resistance",
            "May protect the brain from aging "
        ]
        
    },
    {
        productId: 17,
        category: "tea",
        productName: "Yellow Orthodox-tea",
        grade: "Whole rolled leaf",
        pricing: {
            "100g": 300
        },
        image: "yellow-tea.jpg",
        description: "Yellow tea comes from the processed leaves of the plant Camellia Sinensis.  It process of preparation is similar to that of green tea, with additional step of encasing and steaming the beverage.",
        healthBenefits: [
            "May promote digestive health",
            "Could promote heart health",
            "May lower risk of insulin resistance",
            "May protect the brain from aging "
        ]
    },
    {
        productId: 18,
        category: "tea",
        productName: "Instant Green Tea",
        grade: "Cold Water Extract",
        pricing: {
            "100g": 450
        },
        image: "ctc-black-tea.jpg",
        description: "Our orthodox tea are processed from ordinary tea bushes. They are processed by rolling the leaf whole without fermenting during production. From the cup brews, our customers will enjoys lovely apples and caramel fragrance and aroma, full bodied, with soft, sweet with smooth appealing taste.",
        healthBenefits: [
            "Rich in anti-oxidants",
            "Could help you loose weight",
            "May lower risk of insulin resistance",
            "May protect the brain from aging "
        ]
    },
    {
        productId: 19,
        category: "tea",
        productName: "Instant Black Tea",
        grade: "Cold Water Extract",
        pricing: {
            "100g": 450
        },
        image: "instant-tea.jpg",
        description: "Our orthodox tea are processed from ordinary tea bushes. They are processed by rolling the leaf whole without fermenting during production. From the cup brews, our customers will enjoys lovely apples and caramel fragrance and aroma, full bodied, with soft, sweet with smooth appealing taste.",
        healthBenefits: [
            "Rich in anti-oxidants",
            "Could help you loose weight",
            "May lower risk of insulin resistance",
            "May protect the brain from aging "
        ]
    },
    {
        productId: 20,
        category: "tea",
        productName: "Instant Black Tea",
        grade: "Hot Water Extract",
        pricing: {
            "100g": 450
        },
        image: "ctc-black-tea.jpg",
        description: "Our orthodox tea are processed from ordinary tea bushes. They are processed by rolling the leaf whole without fermenting during production. From the cup brews, our customers will enjoys lovely apples and caramel fragrance and aroma, full bodied, with soft, sweet with smooth appealing taste.",
        healthBenefits: [
            "Rich in anti-oxidants",
            "Could help you loose weight",
            "May lower risk of insulin resistance",
            "May protect the brain from aging "
        ]
    },

]
export default allProductsList