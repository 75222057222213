import { createContext, useState, useEffect } from "react";
import allProductsList from "../productsDB";

export const ProductsContext = createContext();

export const ProductsContextProvider = (props) => {
  const [cartList, setCartList] = useState([]);

  const productsList = allProductsList;

  // check local storage for previously store cart items
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("cartList"));
    if (items) {
      setCartList(items);
    }
  }, []);

  // update localStorage when CartList changes
  useEffect(() => {
    const updateLocalStorage = (cartList) => {
      localStorage.setItem("cartList", JSON.stringify(cartList));
    };
    updateLocalStorage(cartList);
  }, [cartList]);

  const addToCartClickHandler = (cartItem) => {
    if (
      cartList.some(
        (item) =>
          item.productId === cartItem.productId &&
          item.package === cartItem.package
      )
    ) {
      removeFromCart(cartItem.productId, cartItem.package);
      setCartList((prevState) => [...prevState, cartItem]);
    } else {
      setCartList((prevState) => [...prevState, cartItem]);
    }
  };
  const removeFromCart = (id, pkg) => {
    const newCartList = cartList.filter(
      (cartItem) => !(cartItem.productId === id && cartItem.package === pkg)
    );
    setCartList(newCartList);
  };

  const clearCartList = () => {
    setCartList([]);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [showCartPanel, setShowCartPanel] = useState(false);

  window.addEventListener("scroll", () => setShowCartPanel(false));
  return (
    <ProductsContext.Provider
      value={{
        productsList,
        cartList,
        setCartList,
        clearCartList,
        addToCartClickHandler,
        removeFromCart,
        showCartPanel,
        setShowCartPanel,
        scrollToTop,
      }}
    >
      {props.children}
    </ProductsContext.Provider>
  );
};
