import {Link} from "react-router-dom"
import {useContext, useState} from "react"
import {ProductsContext} from "../contexts/ProductsContext"
import { formatCurrency } from "../utilities/formatCurrency"

const ProductCard = ({id}) => {

    const {scrollToTop, productsList,addToCartClickHandler} = useContext(ProductsContext)
    let currentProduct = productsList.find(product => product.productId === id)

    const [currPackage, setCurrPackage] = useState(Object.keys(currentProduct.pricing)[0])


    const [updateSuccessful, setUpdateSuccessful] = useState(false)

   
    // create state for curreQuantity incase the current im[lementation becomes buggy/faulty

    currentProduct = {...currentProduct,cartId: "", quantity: 1, package: currPackage }

  return (
    
    <li>
        <div className="product-card">

        <figure className="card-banner">
            <img src={`./assets/images/${currentProduct.image}`} width="189" height="189" loading="lazy" alt={currentProduct.productName}/>
        </figure>

        <div className="grading-wrapper">
            <span>GRADE :</span>  
            <span>{currentProduct.grade}</span>
        </div>
        <h3 className="h4 card-title">
        <Link to= {`/products/${currentProduct.productId}`} onClick={()=>scrollToTop()}  >
            {currentProduct.productName}
        </Link>
        </h3>

        <div className="pricing-wrapper">
            <span className="quantity">
            <select onChange={e=>setCurrPackage(e.target.value)}  id="cars" name="cars">
                {
                  Object.keys(currentProduct.pricing).map((key,index)=>{
                    return(
                        <option key={index} value={key}>{key}</option>
                    )
                  })
                }
               
            </select>
            </span>

            {/* <data className="price" style={{minWidth: "60px"}} value="">{`KSh.${currentProduct.pricing[currPackage]}`}</data> */}
            <data className="price" style={{minWidth: "60px"}} value="">{formatCurrency(currentProduct.pricing[currPackage])}</data>
            
            <input onChange={(e)=>currentProduct.quantity = e.target.value} type="number" defaultValue="1" min="0" style={{backgroundColor: "white", width: "34px", border: "1px solid grey"}} />
        </div>
        <div className="product-buttons-wrapper" style={{position: "relative"}}>

        
        <button onClick={()=>{
          addToCartClickHandler(currentProduct)
          setUpdateSuccessful(true)
          setTimeout(() =>{
            setUpdateSuccessful(false)
          },3000)
          }} className="btn btn-primary disabled">Add to Cart</button>
          
        
        {updateSuccessful &&  <ion-icon style={{position: 'absolute',right: '0',top: '0', bottom: '0',height: '100%', color: 'green', fontSize: '2rem'}} name="checkmark-circle-outline"></ion-icon>
        }
        </div>

        </div>
    </li>
    
  )
}

export default ProductCard