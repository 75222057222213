import {Link} from 'react-router-dom'

const Cta = () => {
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  };
  return (
    <section className="cta">
        <div className="container">
          <p className="section-subtitle"> --  Think Outside the Cup  --</p>

          <h2 className="h2 section-title">Our company value preposition highly articulates on ensuring our strategic trading partners and customers derive their needs and expectations while using our coffee and tea products. </h2>

          <p className="cta-text">
            Buy Quality, Earn Your Reward 
          </p>

          {/* <Link to="#cofee-list" behavior="smooth" className="btn btn-primary">
            <span>Shop Now</span>

            <ion-icon name="chevron-forward" aria-hidden="true"></ion-icon>
          </Link> */}

        </div>
      </section>
  )
}

export default Cta