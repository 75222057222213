import {Link} from 'react-router-dom'
import "./BreadCrumpWrapper.css"

const BreadCrumpWrapper = ({pageTitle,pageUrl, id}) => {
  return (
    <>
        <div className="breadcumb-wrapper" id={id} >

            <h2 className="page-title">{pageTitle}</h2>

            <ol className="breadcumb-list">

            <li className="breadcumb-item">
                <Link to="/" className="breadcumb-link">Home</Link>
            </li>

            <li className="breadcumb-item">{pageUrl}</li>

            </ol>

        </div>
    </>
  )
}

export default BreadCrumpWrapper