import "./Home.css"
import Hero from "../components/homeComponents/Hero"
import WhyUs from "../components/homeComponents/WhyUs"
import Offers from "../components/homeComponents/Offers"
import CoffeeList from "../components/homeComponents/CoffeeList"
import Cta from "../components/homeComponents/Cta"
import TeaList from "../components/homeComponents/TeaList"
import LetsTalk from "../components/homeComponents/LetsTalk"
import Partners from "../components/homeComponents/Partners"
import Testimonials from "../components/homeComponents/Testimonials"
const Home = () => {
  return (
    <main>
        <article>
            <Hero />
            <WhyUs />
            <Offers />
            <CoffeeList />
            <Cta />
            <TeaList />
            <Partners />
            <Testimonials />
            <LetsTalk />
        </article>
    </main>
  )
}

export default Home