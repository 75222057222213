import React from 'react'
import dhlLogo from "../../images/partner-dhl.png"
import eataPartner from "../../images/partner-eata.png"
import brandkePartner from "../../images/partner-brandke.png"
import afaPartner from "../../images/partner-afa.png"
import ktdaPartner from "../../images/partner.png"

const Partners = () => {
  return (
    <section className="section partner">
        <div className="container">

          <p className="section-subtitle"> --  Not just a coffee shop, it’s a community --</p>

          <h2 className="h2 section-title">Trusted Partners</h2>

          <ul className="has-scrollbar">

            <li className="partner-item">
              <figure className="partner-logo">
                <img src={ktdaPartner} width="162" height="134" loading="lazy" alt="Partner logo"/>
              </figure>
            </li>

            <li className="partner-item">
              <figure className="partner-logo">
                <img src={dhlLogo} width="162" height="134" loading="lazy" alt="Partner logo"/>
              </figure>
            </li>

            <li className="partner-item">
              <figure className="partner-logo">
                <img src={eataPartner} width="162" height="134" loading="lazy" alt="Partner logo"/>
              </figure>
            </li>

            <li className="partner-item">
              <figure className="partner-logo">
                <img src={brandkePartner} width="162" height="134" loading="lazy" alt="Partner logo"/>
              </figure>
            </li>

            <li className="partner-item">
              <figure className="partner-logo">
                <img src={afaPartner} width="162" height="134" loading="lazy" alt="Partner logo"/>
              </figure>
            </li>
          </ul>

        </div>
      </section>
  )
}

export default Partners