import React from 'react'
import Intro from '../components/aboutComponents/Intro'
import BreadCrumpWrapper from "../components/BreadCrumpWrapper"
import RightImageSection from "../components/RightImageSection"
import LeftImageSection from "../components/LeftImageSection"
import Team from '../components/aboutComponents/Team'


const About = () => {
  return (
    <main>
      <article>
        <BreadCrumpWrapper pageTitle="Company Profile" pageUrl="About" id="about-bread"/>
        <Intro />
        <RightImageSection 
          sectionTitle="OUR VISION" 
          sectionText="To lead Sustainable Consumption and Production and exports of specialty Arabica coffee and Tea products, that guarantee, unique quality attributes to buyers and consumers’ values, positively impacting, socio-economic gains on the supply value chain."
          sectionImage="processing-cycle.jpg"
        />
        <LeftImageSection 
          sectionTitle="OUR MISION" 
          sectionText="To provide our customers with quality coffee and tea from farm to cup at a price that is fair to both the grower and the customers."
          sectionImage="quality-testing.jpg"
        />
        <Team />
    
       
      </article>
    </main>
  )
}

export default About