import {useContext} from "react"
import {Link} from "react-router-dom"
import {ProductsContext} from "./contexts/ProductsContext"
import PanelItem from './components/PanelItem'

const Aside = () => {
  
  const {cartList,showCartPanel,scrollToTop, setShowCartPanel} = useContext(ProductsContext)
  let totalAmount = 0
  cartList.forEach(item=>{
    totalAmount += item.pricing[item.package] * item.quantity
  })
  return (
    
    <aside className="aside">

      
        <div  className={showCartPanel? "side-panel active": "side-panel"} data-side-panel="cart">

            <button onClick={()=>setShowCartPanel(false)} className="panel-close-btn" aria-label="Close cart" data-panel-btn="cart">
            <ion-icon name="close-outline"></ion-icon>
            </button>
            {cartList?.length===0 && <div style={{color: "green"}}>Your cart is empty. Go shopping.</div>}
            <ul className="panel-list" style={{overflowX: "auto"}}>
              {
                cartList.map((cartItem, index)=><PanelItem key={index} id={cartItem.productId} pkg={cartItem.package}  />)
              }
              
            </ul>

            <div className="subtotal">
            <p className="subtotal-text">Subtotal:</p>

            <data className="subtotal-value" value="215.14">KSh.{totalAmount}</data>
            </div>

            <Link to="/checkout" onClick={()=>{
                scrollToTop()
                setShowCartPanel(false)
              }} className="panel-btn">Checkout</Link>

        </div>

    </aside>
  )
}

export default Aside