import {Link} from "react-router-dom"
const Hero = () => {
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  };
  return (
    <section className="hero">
        <div className="container">

          <div className="hero-content hide-for-small">

            <p className="hero-subtitle">Free delivery within Nairobi(any offer) CBD.</p>

            <h2 className="h1 hero-title">
              Mount Kenya <span className="span">specialty</span>
              tea & <span className="span">coffee.</span>
            </h2>

            <p className="hero-text">
              Buy Quality, Earn Your Reward.
            </p>

            {/* <Link to="#cofee-list" onClick={()=>scrollToTop()} className="btn btn-primary">
              <span className="span">Buy Now</span>

              <ion-icon name="chevron-forward" aria-hidden="true"></ion-icon>
            </Link> */}

          </div>
          <div className="hero-content only-small">

            <p className="hero-subtitle">Buy Quality, Earn Your Reward.</p>

            <p className="hero-text">
              Our focus is on specialty coffee and tea products, with high premium and unique quality attributes that guarantee you, our customers, with specific tastes and preferences as well as value for your money. Our priorities lie heavily on products sustainability, traceability and protection of environment ecosystems to mitigate climate change. 
            </p>

            {/* <Link to="/shop" onClick={()=>scrollToTop()} className="btn btn-primary">
              <span className="span">Buy Now</span>

              <ion-icon name="chevron-forward" aria-hidden="true"></ion-icon>
            </Link> */}

          </div>

          <div >
          <figure className="hero-banner">
            <img src="./assets/images/hero-cup.png" width="303" height="334" loading="lazy" alt="Hot Cup"/>
          </figure>
          </div>

        </div>
      </section>
  )
}

export default Hero