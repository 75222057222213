import {useState, useEffect, useContext} from 'react'
import {ProductsContext} from "../../contexts/ProductsContext"
import "./DeliveryDetails.css"
import axios from 'axios'
import { formatCurrency } from '../../utilities/formatCurrency'





const DeliveryDetails = ({checkoutTotal}) => {
    const {cartList,clearCartList} = useContext(ProductsContext)
    const [inputs, setInputs] = useState({});
    const [updateSuccessful, setUpdateSuccessful] = useState(false)

    const [makingOrder, setMakingOrder] = useState(false)
    const [orderingError, setOrderingError] = useState(false)
    const [orderingSuccess, setOrderingSuccess] = useState(false)
    const [detailsError, setDetailsError] = useState(true)
   
   

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
      
    }
    useEffect(()=>{
        if(localStorage.getItem("inputs")){
            setInputs(JSON.parse(localStorage.getItem("inputs"))); 
            setDetailsError(false)
        }
        
    },[cartList])
    
    const saveDetails = (e)=>{
        e.preventDefault()
        localStorage.setItem("inputs", JSON.stringify(inputs))
        setDetailsError(false)
        setUpdateSuccessful(true)
        setTimeout(()=>{
            setUpdateSuccessful(false)
        }, 2000)
    }

    const [selectedMethod, setSelectedMethod]= useState('mpesa')
    const handlePaymentMethodChange = (e)=>{
        setSelectedMethod(e.target.value)
    }

    // filter cartlist tot remove unnecesary buggage(healthbenefits and description)

    cartList.forEach(cartItem => {
      delete cartItem.description
      delete cartItem.healthBenefits
      delete cartItem.cartId
      delete cartItem.qualityCupScore
      delete cartItem.image
    })
    let order = {checkoutTotal: checkoutTotal, ...inputs, cartList: JSON.stringify(cartList)} // add cart list
   
    
   

    const makeOrder = async (e) =>{
      e.preventDefault()
      if(cartList.length === 0){
        setDetailsError(true)
        setTimeout(()=>{
          setDetailsError(false)
        },10000)
      }else{
        setMakingOrder(true)
        await axios
                .post("https://api.kenyaspecialtyteacoffee.co.ke/orders", {order})
                .then((response=>{
                  setMakingOrder(false)
                  if(response.data.error){
                    setOrderingError(true)
                    setTimeout(()=>{
                      setOrderingError(false)
                    },15000)
                  }else{
                    setOrderingSuccess(true)
                    setTimeout(()=>{
                      setOrderingError(false)
                      clearCartList()
                      setOrderingSuccess(false)
                    },8000)
                  }
                }))
                .catch((err)=>{
                  setOrderingError(true)
                  setMakingOrder(false)
                  setTimeout(()=>{
                    setOrderingError(false)
                  },15000)
                })
      }
    }

  
    const renderPaymentDetails=(selectedMethod, total, inputs, makeOrder, makingOrder, orderingError,orderingSuccess, detailsError)=>{
        switch(selectedMethod){
          case 'card':
            return <CardPayment makeOrder={makeOrder} />; 
          case 'paypal':
            return <PayPalPayment makeOrder={makeOrder} />;
          case 'bank':
            return <BankPayment makeOrder={makeOrder} makingOrder= {makingOrder} orderingError={orderingError} orderingSuccess={orderingSuccess} detailsError={detailsError} />;
          case 'delivery':
            return <DeliveryPayment {...inputs} makeOrder={makeOrder} makingOrder= {makingOrder} orderingError={orderingError} orderingSuccess={orderingSuccess} detailsError={detailsError}/>;
          case 'other':
            return <OtherPayment {...inputs} makeOrder={makeOrder} makingOrder= {makingOrder} orderingError={orderingError} orderingSuccess={orderingSuccess} detailsError={detailsError}/>;
          default:
            return <MpesaPayment total={total}  makeOrder={makeOrder} makingOrder= {makingOrder} orderingError={orderingError} orderingSuccess={orderingSuccess} detailsError={detailsError}/>;
        }
    }


    
  
  return (
    <div className="checkout-box checkout-payment">
    <div className="personal-details">
      <p className="h3 details-title"> <span> Your Contact Details </span> <ion-icon name="pencil-outline"></ion-icon> </p>
      <form onSubmit={saveDetails} className="details-form">
        <div className="form-group">
            <label htmlFor="uname">Name/Company:</label>
            <input type="text" name="uname" id="uname" value={inputs.uname || ""} onChange={handleChange} />
        </div>
        <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" name="email" id="email" value={inputs.email || ""} onChange={handleChange} required />
        </div>
        <div className="form-group">
            <label htmlFor="phone">Phone&nbsp;Number:</label>
            <input type="text" maxLength="15" name="phone" id="phone" value={inputs.phone || ""} onChange={handleChange} />
        </div>
        <div className="form-group">
            <label htmlFor="location">Delivery&nbsp;Destination:</label>
            <input type="text" name="location" id="location" value={inputs.location || ""} onChange={handleChange} />
        </div>
        
        <div className="form-group">
            <label htmlFor="payment">Prefered Payment Method:</label>
            <input type="text" name="payment" id="payment" value={inputs.payment || ""} onChange={handleChange} />
        </div>
        <div className="form-group">
            <label htmlFor="comment">Comment:</label>
            <input type="text" name="comment" id="comment" value={inputs.comment || ""} onChange={handleChange} />
        </div>
        {
            updateSuccessful? (
                <input id="update-success" type="submit" value="Updated" disabled/>

            ):(
                <input type="submit" value="Update Details" />

            )
        }
    </form>
     
    </div>
    <p className="checkout-payment-title">Checkout: {formatCurrency(checkoutTotal)}</p>
    <div className="checkout-padding">
      <label htmlFor="pay-method" className="checkout-card-title">Choose a payment method</label>
      <div className="payment-methods" onChange={(e)=>handlePaymentMethodChange(e)}> 

            <div className="payment-method-form-group">
          <input type="radio" name="pay-method" value="mpesa" id="mpesa-btn" defaultChecked /> <label htmlFor="mpesa-btn">M-pesa Payment</label></div>
          <div className="payment-method-form-group">
          <input type="radio" name="pay-method" value="card" id="card-btn" /> <label htmlFor="card-btn"> Card Payment</label></div>
          <div className="payment-method-form-group">
          <input type="radio" name="pay-method" value="paypal" id="paypal-btn" /><label htmlFor="paypal-btn">PayPal</label></div>
          <div className="payment-method-form-group">
          <input type="radio" name="pay-method" value="delivery" id="delivery-btn" /><label htmlFor="delivery-btn">Pay on Delivery</label></div>
          <div className="payment-method-form-group">
          <input type="radio" name="pay-method" value="bank" id="bank-btn" /><label htmlFor="bank-btn">Bank Payment</label></div>
          <div className="payment-method-form-group">
          <input type="radio" name="pay-method" value="other" id="other-btn" /><label htmlFor="other-btn">Other option</label></div>
      </div>
      <hr style={{margin:"16px 0"}} />
      {
        renderPaymentDetails(selectedMethod, checkoutTotal, inputs, makeOrder, makingOrder, orderingError,orderingSuccess,detailsError)
      }
      <hr style={{margin:"16px 0"}} />
      <center className="h3" >The contact details above will be used to confirm your order and for further communications only pertaining to the order. </center>
    </div>
  </div>
   
  )
}

export default DeliveryDetails



const MpesaPayment = ({total, makeOrder, makingOrder, orderingError, orderingSuccess, detailsError})=>{
    return(
      <>
        <div className="mpesa-card">
            <p className="p">Go to Sim toolkit / Mpesa app</p>
            <p className="p">Select MPESA</p>
            <p className="p">Select <span>Lipa na Mpesa</span> </p>
            <p className="p">Select <span>Pay Bill</span> </p>
            <p className="p">Enter Business Number: <span>400200</span></p>
            <p className="p">Account no. : <span>40010620</span></p>
            <p className="p">Enter Amount. : <span>{formatCurrency(total)}</span></p>
            <p className="p">Enter MPESA PIN. : <span>****</span></p>
            <p className="p">Account Name:<span>"Mount Kenya Specialty Tea & Coffee Co Ltd"</span></p>
            <form onSubmit={makeOrder} className="complete-order-form">
                <p>One of our agents will call you once you complete the order.</p>
                {
                  makingOrder?(<input type="submit" disabled value="Completing..." className="completing-order" />):(
                    detailsError?<input type="submit" value="Complete Order" disabled style={{filter: "brightness(80%)"}}/>:
                  <input type="submit" value="Complete Order" />
                  )
                }
            </form> 
            {
              orderingError && <p style={{color:"red", textAlign:"center", fontSize:"small"}}>Something went wrong. Try again in minute</p>          

            }
            {
              orderingSuccess && <p style={{color:"green", textAlign:"center", fontSize:"small"}} >Order placed Successfully. We will contact you shortly.</p>  
            }
            {
              detailsError && <p style={{color:"red", textAlign:"center", fontSize:"small"}}>You must provide <b>name </b>and <b>email address</b> in contact details and have atleast <b> one item in the cart</b> to make an order.</p>
            }
        </div>
      </>
    )
  }
  
  
  const CardPayment = ()=>{
    return(
      <>
        <h3 className="checkout-card-title">Card Payment</h3>
        <div className="mpesa-card">
            These details comming soon ..... <br />
            Contact the <a href="mailto:admin@kenyaspecialtyteacoffee.co.ke" style={{display: "inline", color: "var(--emerald)"}}>admin</a> for further assistance or simply choose <b>Other option</b> above.
        </div>
      </>
    )
  }
  const PayPalPayment = ()=>{
    return(
      <>
        <h3 className="checkout-card-title">Pay on PayPal</h3>
        <div className="mpesa-card">
            These details comming soon ..... <br />
            Contact the <a href="mailto:admin@kenyaspecialtyteacoffee.co.ke" style={{display: "inline", color: "var(--emerald)"}}>admin</a> for further assistance or simply choose <b>Other option</b> above.
        </div>
      </>
    )
  }
  const DeliveryPayment = ({uname, phone,email, location, comment, makeOrder,makingOrder, orderingError, orderingSuccess, detailsError})=>{
    return(
      <>
        <h3 className="checkout-card-title">Delivery Details</h3>
        <div className="mpesa-card">
            Recipient: <b>{uname}</b>  <br />
            Phone:  <b>{phone}</b> <br />
            Email: <b>{email}</b>  <br />
            Comment: <b> {comment}</b> <br />

            Delivery Location: <b>{location}</b>  <br />  Contact Person: <b>Simon Gikanyi </b> <br />
            Phone: <b>+254723467903</b> <br />
            Email: <b>mt.kenyaspecialtyteacoffee@gmail.com</b> <br />
            <form onSubmit={makeOrder} className="complete-order-form">
                <p>One of our agents will call you once you complete the order.</p>
                {
                  makingOrder?(<input type="submit" disabled value="Completing..." className="completing-order" />):(
                    detailsError?<input type="submit" value="Complete Order" disabled style={{filter: "brightness(80%)"}}/>:
                  <input type="submit" value="Complete Order" />
                  )
                } 
            </form> 
            {
              orderingError && <p style={{color:"red", textAlign:"center", fontSize:"small"}}>Something went wrong. Try again in minute</p>          

            }
            {
              orderingSuccess && <p style={{color:"green", textAlign:"center", fontSize:"small"}} >Order placed Successfully. We will contact you shortly.</p>  
            }
            {
              detailsError && <p style={{color:"red", textAlign:"center", fontSize:"small"}}>You must provide <b>name </b>and <b>email address</b> in contact details and have atleast <b> one item in the cart</b> to make an order.</p>
            }
        </div>
      </>
    )
  }
  const BankPayment = ({makeOrder,makingOrder, orderingError, orderingSuccess, detailsError})=>{
    return(
      <>
        <div className="mpesa-card">
            Bank: <b>Co-operative Bank Kenya</b>  <br />
            Dollar Account: <b>02100768887900</b> <br />
            Account Name: <b>Mount Kenya Specialty Tea & Coffee Co. Ltd</b>  <br />
            Branch: <b> Ruiru</b> <br />

            Swift Code: <b>KCOOKENA-11138</b>  <br />           <form onSubmit={makeOrder} className="complete-order-form">
                <p>One of our agents will call you once you complete the order.</p>
                {
                  makingOrder?(<input type="submit" disabled value="Completing..." className="completing-order" />):(
                    detailsError?<input type="submit" value="Complete Order" disabled style={{filter: "brightness(80%)"}}/>:
                  <input type="submit" value="Complete Order" />
                  )
                } 
            </form> 
            {
              orderingError && <p style={{color:"red", textAlign:"center", fontSize:"small"}}>Something went wrong. Try again in minute</p>          

            }
            {
              orderingSuccess && <p style={{color:"green", textAlign:"center", fontSize:"small"}} >Order placed Successfully. We will contact you shortly.</p>  
            }
            {
              detailsError && <p style={{color:"red", textAlign:"center", fontSize:"small"}}>You must provide <b>name </b>and <b>email address</b> in contact details and have atleast <b> one item in the cart</b> to make an order.</p>
            }
        </div>
      </>
    )
  }
  const OtherPayment = ({uname, phone,email, payment, makeOrder,makingOrder, orderingError, orderingSuccess, detailsError})=>{
    return(
      <>
        <h3 className="checkout-card-title">Simply Complete order we will contact you</h3>
        <div className="mpesa-card">
            Name: <b>{uname}</b> <br />
            Phone: <b>{phone}</b> <br />
            Email: <b>{email}</b>  <br />
            Prefered mode of payment: <b>{payment}</b><br />
            <form onSubmit={makeOrder} className="complete-order-form">
                <p>One of our agents will call you once you complete the order.</p>
                {
                  makingOrder?(<input type="submit" disabled value="Completing..." className="completing-order" />):(
                    detailsError?<input type="submit" value="Complete Order" disabled style={{filter: "brightness(80%)"}}/>:
                  <input type="submit" value="Complete Order" />
                  )
                }  
            </form>
            {
              orderingError && <p style={{color:"red", textAlign:"center", fontSize:"small"}}>Something went wrong. Try again in minute</p>          

            }
            {
              orderingSuccess && <p style={{color:"green", textAlign:"center", fontSize:"small"}} >Order placed Successfully. We will contact you shortly.</p>  
            }
            {
              detailsError && <p style={{color:"red", textAlign:"center", fontSize:"small"}}>You must provide <b>name </b>and <b>email address</b> in contact details and have atleast <b> one item in the cart</b> to make an order.</p>
            }
        </div>
      </>
    )
  }
  