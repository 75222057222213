import {useForm} from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import {useState} from 'react'



const LetsTalk = () => {
  const {register,reset, formState: { errors }, handleSubmit}= useForm({criteriaMode: "all"})
  const [messageSuccess, setMessageSuccess] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [sending, setSending] = useState(false)


  const clearForm = () =>{
    reset({
      email: "",
      clientname: "",
      phone: "+",
      company: "",
      message: "",
    })
  }
  // http://api.kenyaspecialtyteacoffee.co.ke/messages
  const handleMessageSubmit = (data)=>{
    setSending(true)
    axios
      .post("http://api.kenyaspecialtyteacoffee.co.ke/messages", data)
      .then((response)=>{
        if(response.status===200){
          setSending(false)
          setMessageSuccess(true)
          clearForm()
          setTimeout(()=>{
            setMessageSuccess(false)
          }, 13000)
        }else{
          setSending(false)
          setMessageError(true)
          setTimeout(()=>{
            setMessageError(false)
          }, 25000)
        }
        
      })
      .catch((err)=>{
        // console.log(err)
        setSending(false)
        setMessageError(true)
        setTimeout(()=>{
          setMessageError(false)
        }, 25000)
      })
  }

  return (
    <section className="section letstalk">
        <div className="container">
          <div className="letstalk-card">
          <p className="section-subtitle"> -- Get our coffee, buy time. --</p>

          <h2 className="h2 section-title">Let's Talk</h2>
          <div className="letstalk-flex">
          <form onSubmit={handleSubmit(handleMessageSubmit)} className="letstalk-form letstalk-item">
            <label htmlFor="clientname">Name: * 
            {
              <ErrorMessage
              errors={errors}
              name="clientname"
              render={({ messages }) => {
                return messages
                  ? Object.entries(messages).map(([type, message]) => (
                      <span className="form-error-message" key={type}>{message}</span>
                    ))
                  : null;
              }}
            />
            }
            
             </label>
            
            <input type="text" id="clientname" {...register("clientname", {
                required: "Please provide a name",
              })} />
            <br/>
            <label htmlFor="email">Email: *
            {
              <ErrorMessage
              errors={errors}
              name="email"
              render={({ messages }) => {
                return messages
                  ? Object.entries(messages).map(([type, message]) => (
                      <span className="form-error-message" key={type}>{message}</span>
                    ))
                  : null;
              }}
            />
            }
            </label>
            
            <input type="email" {...register("email", {required: "Please provide an email we can reach you with."})} id="email"/><br />
            <label htmlFor="company">Company Name:</label>
            <input type="text" {...register("company")} defaultValue="" id="company"/><br />
            <label htmlFor="phone">Phone Number: +2547....</label>
            <input type="text" {...register("phone")} defaultValue="+" id="phone"/><br />
            <label htmlFor="message">Whats on you mind? *
            {
              <ErrorMessage
              errors={errors}
              name="message"
              render={({ messages }) => {
                return messages
                  ? Object.entries(messages).map(([type, message]) => (
                      <span className="form-error-message" key={type}>{message}</span>
                    ))
                  : null;
              }}
            />
            }

            </label>
            <textarea name="message" {...register("message", {required: "Blank message."})} id="message" rows="3"></textarea><br />
            <button type="submit" className="btn btn-with-loader" >
             
              {sending? 
                <>
                 <div>Sending ...</div>
                <div className="lds-dual-ring"></div>
                </>
                : <>Send Message</>
              }
            
            </button>
            {messageSuccess && <p className="form-success-message">Message Sent Successfully</p>}
            {messageError? (
              <p className="form-error-message" >Sorry!!. A problem was encountered when sending your message, please try again in minute. If the problem persists, please contact the <a href="mailto:admin@kenyaspecialtyteacoffee.co.ke">admin</a> </p>
            ): ""}
          </form>
          
          </div>
        </div>
        </div>
      </section>
    
  )
}

export default LetsTalk