import {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import axios from "axios"

const Footer = () => {
  const [showScrollBtn, setShowScrollBtn] = useState(false)
  useEffect(() => {
      window.addEventListener('scroll', () => {
          if (window.scrollY > 400) {
            setShowScrollBtn(true);
          } else {
            setShowScrollBtn(false);
          }
      });
  }, []);
  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  };

  // news letter subscription
  const [subscriberEmail, setSubscriberEmail] = useState("")
  const [responseData, setResponseData] = useState([])
  const [sending, setSending] = useState(false)
  // console.log(responseData)
  const clearResponse = ()=>{
    setTimeout(()=>{
        setResponseData([])
        setSubscriberEmail("")
        setSending(false)
    },15000)
  }
  // const baseURL = "http://localhost:3300/mailer"
  const baseURL = "https://api.kenyaspecialtyteacoffee.co.ke/mailer"
  const handleNewsLetterSubscription =(event)=>{
    event.preventDefault();
    setSending(true)
    axios.post(baseURL, {emailSub: subscriberEmail})
    .then(response=>{
      setResponseData(response.data)
      // CATCH BACKEND ERROR HERE- use affected rows!!
      clearResponse()
    })
    .catch(error=>{
      console.error(error)
      clearResponse()
    })

    // axios
    //   .post("http://api.kenyaspecialtyteacoffee.co.ke/mailer", {emailSub: subscriberEmail})
    //   .then((response)=>{
    //     console.log(response.data)
    //     clearResponse()

    //   })
    //   .catch((err)=>{
    //     console.log(err)
    //     clearResponse()
    //   })
    
    
  }
  return (
    <>
    <footer className="footer">

    <div className="footer-top">
      <div className="container">
        <div className="footer-brand">
        <p className="footer-list-title">Company</p>
          <p className="footer-text">
            Mount Kenya <strong>Specialty</strong>  Tea & <strong>Coffee </strong> is a private, limited company; family owned and was incorporated in 2016. The company is licensed by AFA-Coffee Directorate to trade in green coffee beans and coffee roasting for both exports and domestic consumptions. The company is also licensed by the Tea Board of Kenya, as tea packer, tea buyer and exporter.  
          </p>

          <ul className="social-list">

            <li>
              <a href="/" className="social-link">
                <ion-icon name="logo-facebook"></ion-icon>
              </a>
            </li>

            <li>
              <a href="/" className="social-link">
                <ion-icon name="logo-twitter"></ion-icon>
              </a>
            </li>

            <li>
              <a href="/" className="social-link">
                <ion-icon name="logo-skype"></ion-icon>
              </a>
            </li>

            <li>
              <a href="/" className="social-link">
                <ion-icon name="logo-linkedin"></ion-icon>
              </a>
            </li>

          </ul>

        </div>

        <ul className="footer-list">

          <li>
            <p className="footer-list-title">Quick Links</p>
          </li>

          <li>
            <Link to="/" onClick={()=>goToTop()} className="footer-link">Home</Link>
          </li>
          <li>
            <Link to="/shipping" onClick={()=>goToTop()} className="footer-link">Shipping & Logistics</Link>
          </li>
       
          <li>
            <Link to="/tea" onClick={()=>goToTop()} className="footer-link">Our Tea</Link>
          </li>

          <li>
            <Link to="/coffee" onClick={()=>goToTop()} className="footer-link">Our Coffee</Link>
          </li>

          <li>
            <Link to="/about" onClick={()=>goToTop()} className="footer-link">About Us</Link>
          </li>

          <li>
            <Link to="/contact" onClick={()=>goToTop()} className="footer-link">Contact Us</Link>
          </li>

        </ul>

        <ul className="footer-list">

          <li>
            <p className="footer-list-title">Contact</p>
          </li>

          <li className="footer-item">
            <div className="contact-icon">
              <ion-icon name="location-outline"></ion-icon>
            </div>

            <address className="contact-link">
              Ruiru Murera Sisal, Ruiru-Kimbo road, off Thika Super Highway.
            </address>
          </li>

          <li className="footer-item">
            <div className="contact-icon">
              <ion-icon name="call-outline"></ion-icon>
            </div>

            <a href="tel:+254723467903" className="contact-link">+254 723467903 </a>
          </li>

          <li className="footer-item">
            <div className="contact-icon">
              <ion-icon name="mail-outline"></ion-icon>
            </div>

            <a href="mailto:info@kenyaspecialtyteacoffee.co.ke" className="contact-link">info@kenyaspecialtyteacoffee.co.ke</a>
          </li>
          <li className="footer-item">
            <div className="contact-icon">
              <ion-icon name="mail-outline"></ion-icon>
            </div>

            <a href="mailto:mt.kenyaspecialtyteacoffee@gmail.com" className="contact-link">mt.kenyaspecialtyteacoffee@gmail.com </a>
          </li>
          <li className="footer-item">
            <div className="contact-icon">
            <ion-icon name="logo-youtube"></ion-icon>
            </div>

            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="contact-link">Mount Kenya Specialty </a>
          </li>

        </ul>

        <div className="footer-list">

          <p className="footer-list-title">Newsletter</p>

          <p className="newsletter-text">
            You will be the first to know when we have more special offers for you.
          </p>

          <form onSubmit={handleNewsLetterSubscription} className="footer-form">

            <input type="email" name="emailSub" placeholder="Email Address *" required className="footer-input" value={subscriberEmail} onChange={(e)=>setSubscriberEmail(e.target.value)} />

            {
              sending? (
                responseData?.affectedRows?(
                  <button type="submit" className="footer-form-btn blink" aria-label="Submit">
                    <ion-icon name="checkmark-done-outline"></ion-icon>
                  </button>
                )
                :(
                  <button type="submit" className="footer-form-btn" aria-label="Submit">
                    <ion-icon name="cloud-upload-outline"></ion-icon>
                  </button>
                )
              ):(
                <button type="submit" className="footer-form-btn" aria-label="Submit">
                  <ion-icon name="mail-outline"></ion-icon>
                </button>
              )
            }
            
            
          </form>
          {
            responseData?.affectedRows && <p style={{fontSize: "1.35rem",textAlign:"center", fontWeight: "bold", padding: "14px", color: "green" }}>They say "When we come together, great things happen". Lets chase greatness.</p>
          }

        </div>

      </div>
    </div>

    <div className="footer-bottom">
      <div className="container">

        <p className="copyright">
          &copy; 2022 Mount Kenya Specialty Tea & Coffee. All Rights Reserved.
        </p>

        <ul className="footer-bottom-list">

          <li>
            <a href="/" className="footer-bottom-link">Return Policy</a>
          </li>
          <li>
            <a href="/" className="footer-bottom-link">Terms of Service</a>
          </li>

          <li>
            <a href="/" className="footer-bottom-link">Privacy Policy</a>
          </li>
        </ul>

      </div>
    </div>

  </footer>
  <button onClick={goToTop}  className={showScrollBtn? "back-to-top active": "back-to-top"} aria-label="Back to Top" data-back-top-btn>
    <ion-icon name="arrow-up-outline"></ion-icon>
  </button>
  </>
  )
}

export default Footer