import React from 'react'
import "./RightImageSection.css"

const RightImageSection = ({sectionTitle, sectionText, sectionImage, imageDescription}) => {
  return (
    <section className="section offers">
        <div className="container">
            <h3 className="section-title h3 right-image-section-title" >{sectionTitle}</h3>
            <div className="right-image-section">
                <p className="p">{sectionText}</p>
                <div className="section-image-wrapper">
                <img className="section-image" src={`./assets/images/${sectionImage}`} alt={sectionTitle} />
                <p className="image-description">{imageDescription}</p>

                </div>
            </div>
        </div>
    </section>
  )
}

export default RightImageSection