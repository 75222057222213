import {useContext} from 'react'
import {Link} from 'react-router-dom'
import {ProductsContext} from "../contexts/ProductsContext"

const PanelItem = ({id, pkg}) => {

  const {removeFromCart,cartList, setShowCartPanel, scrollToTop} = useContext(ProductsContext)
  const currItem = cartList.find(item=>item.productId===id && item.package===pkg)
  // console.log(currItem)
  return (
    <li className="panel-item">
        <Link 
          to={`/products/${currItem.productId}`} 
          onClick={()=>{
              setShowCartPanel(false)
              scrollToTop()
            }
          } 
          className="panel-card"
        >

          <figure className="item-banner">
              <img src={`./assets/images/${currItem.image}`} width="46" height="46" loading="lazy"
              alt={currItem.productName} />
          </figure>

          <div>
              <p className="item-title">{currItem.productName}</p>

              <span className="item-value">{currItem.package}- KSh.{currItem.pricing[currItem.package]} x {currItem.quantity}</span>
          </div>
     
        </Link>

        <button className="item-close-btn" onClick={()=>removeFromCart(id,pkg)} aria-label="Remove item">
            <ion-icon name="close-outline"></ion-icon>
        </button>


    </li>
  )
}

export default PanelItem