import React from 'react'

const WhyUs = () => {
  return (
    <section className="section service">
        <div className="container">
          <h2 className="h2 section-title">Why our coffee and tea?</h2>

          <ul className="service-list">
          <li className="service-item">
              <div className="item-icon">
                <img src="./assets/images/COFFEE-ONE.png" width="40" height="40" loading="lazy" alt="Helpline icon"/>
              </div>

              <h3 className="h3 item-title">Unique Premium Quality Attributes.</h3>
            </li>

            <li className="service-item">
              <div className="item-icon">
                <img src="./assets/images/service-icon-rain.png" width="40" height="40" loading="lazy" alt="Truck icon"/>
              </div>

              <h3 className="h3 item-title">Sustainable Farming</h3>
            </li>

            <li className="service-item">
              <div className="item-icon">
                <img src="./assets/images/service-icon-traceable.png" width="40" height="40" loading="lazy"
                  alt="Payment card icon"/>
              </div>

              <h3 className="h3 item-title">Traceability</h3>
            </li>

            <li className="service-item">
              <div className="item-icon">
                <img src="./assets/images/service-icon-natural.png" width="40" height="40" loading="lazy" alt="Helpline icon"/>
              </div>

              <h3 className="h3 item-title">Natural Solar Processing</h3>
            </li>
          

          </ul>

        </div>
      </section>
  )
}

export default WhyUs