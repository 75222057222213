import {useContext, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import "./ProductDetails.css"
import {ProductsContext} from "../contexts/ProductsContext"


const ProductDetails = () => {
  const navigate = useNavigate()
  const {productsList, addToCartClickHandler} = useContext(ProductsContext)
  let {id} = useParams()

  let product = productsList.find(item => item.productId === parseInt(id))
  
  const [currPackage, setCurrPackage] = useState(Object.keys(product.pricing)[0])

  const [updateSuccessful, setUpdateSuccessful] = useState(false) 
  // create state for curreQuantity incase the current im[lementation becomes buggy/faulty
  const [currQuantity, setCurrQuantity] = useState(1)

  product = {...product, cartId: "", quantity: currQuantity, package: currPackage }
  return (
    <main>
      <article>

      <section className="section product-details">
        <div className="container">

          <div className="wrapper">

            <div className="product-details-img">

              <figure className="product-display">
                <img src={`../../assets/images/${product.image}`} width="400" height="auto" loading="lazy"
                  alt={product.productName} className="w-100" id="product-image" data-product-display/>
              </figure>
              
            </div>


            <div className="product-details-content">

              <h3 className="product-title">{product.productName}</h3>

              

              <p className="product-text">
                {product.description}
              </p>
              <h4 className="description-title">Health Benefits</h4>
            <ul>
              {product.healthBenefits.map(benefit=>(
                <li key={benefit}>
                  <p className="description-text" style={{lineHeight: "10px", color: "var(--sonic-silver)"}}>
                      {benefit}
                  </p>
                </li> 
              ))}
              
            </ul>
            <button className="btn" onClick={()=>navigate(-1)}><ion-icon name="arrow-back-outline"></ion-icon></button>
{/* 
              <div className="product-weight-wrapper">

                <p className="product-weight-title">Weight :</p>

                <ul className="product-weight-list">
            {
              Object.keys(product.pricing).map(weight=>(
                <li key={weight} className="product-weight-item">
                <input onChange={()=>{
                  setCurrPackage(weight)
                  }} type="radio" name="weight" id={`weight-${weight}`} className="product-weight-radio"/>

                <label htmlFor={`weight-${weight}`}  className="product-weight-label">{weight}</label>
              </li>
              ))
                
            }
                 

                </ul>

              </div>

              <div className="product-qty" style={{position: 'relative'}}>
                <input onChange={(e)=>setCurrQuantity(e.target.value)} type="number" name="quantity" defaultValue="1"  min="1" max="50" className="product-qty-input"/>

                <button 
                onClick={()=>{
                  addToCartClickHandler(product)
                  setUpdateSuccessful(true)
                  setTimeout(() =>{
                    setUpdateSuccessful(false)
                  },3000)
                }}
                className="btn btn-primary product-qty-btn">Add To Cart</button>

                {updateSuccessful &&  <ion-icon style={{position: 'absolute',right: '0',top: '0', bottom: '0',height: '100%', color: 'green', fontSize: '2rem'}} name="checkmark-circle-outline"></ion-icon>
                }
              </div> */}

            </div>

          </div>
          

        </div>
      </section>
      </article>
    </main>
  )
}

export default ProductDetails