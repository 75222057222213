import {Link} from "react-router-dom"

const Offers = () => {
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  };
  return (
    <section className="section offers">
        <div className="container">

          <ul className="offers-list has-scrollbar">

            <li className="offers-item">
              <Link to="/coffee" onClick={()=>scrollToTop()} className="offers-card">

                <figure className="card-banner">
                  <img src="./assets/images/specialty-coffee.jpg" width="292" height="230" loading="lazy"
                    alt="Specialty Coffee Beans" className="w-100"/>
                </figure>

                <div className="card-content">
                  <p className="card-subtitle">Kirima Specialty Coffee</p>

                  <h3 className="h3 card-title">Our passion is to secure specialty Arabica green coffee beans of high, premium and unique quality attributes and supply to our esteemed coffee strategic trading partners.We source our coffee mainly from small scale farmers,medium coffee estates. </h3>

                  <div className="btn btn-primary">Learn More</div>
                </div>
              </Link>
            </li>

            <li className="offers-item">
              <Link to="/tea" onClick={()=>scrollToTop()} className="offers-card">

                <figure className="card-banner">
                  <img src="./assets/images/tea-field.jpg" width="336" height="244" loading="lazy"
                    alt="Specialty Tea Leaves" className="w-100"/>
                </figure>

                <div className="card-content">
                  <p className="card-subtitle">Kirima Specialty Tea </p>

                  <h3 className="h3 card-title">Our tea products supply is from factories owned by small tea growers through “Kenya Tea Development Agency” (KTDA) and factory owned estates from surrounding the slopes of Mount Kenya and Aberdare ranges, hence the name "Kirima".</h3>

                  <div className="btn btn-primary">Learn More</div>
                </div>

              </Link>
            </li>

          </ul>

        </div>
      </section>
  )
}

export default Offers