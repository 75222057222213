import {useState} from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aside from './Aside';
import Header from './Header'
import Footer from "./Footer"
import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
import OurCoffee from './pages/OurCoffee'
import OurTea from './pages/OurTea'
import Shipping from "./pages/Shipping";
import ProductDetails from './pages/ProductDetails'
import Checkout from './pages/Checkout'
import ErrorPage from './pages/ErrorPage'

import "./App.css"
import {ProductsContextProvider} from "./contexts/ProductsContext"


function App() {
  const [showWishListPanel, setShowWishListPanel] = useState(false)
  const [showCartPanel, setShowCartPanel] = useState(false)
  const toggleWishList = ()=>{
    setShowWishListPanel(prevState=>!prevState)
  }
  const toggleCartPanel = ()=>{
    setShowCartPanel(prevState=>!prevState)
  }
  return (
    <ProductsContextProvider>
      <div className="App">
        <Router >

          <Header toggleWishList={toggleWishList} toggleCartPanel={toggleCartPanel} />

          <Aside showCartPanel={showCartPanel} showWishListPanel={showWishListPanel} toggleWishList={toggleWishList} toggleCartPanel={toggleCartPanel} />

          <Routes>

            <Route path="/" element={<Home/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/coffee" element={<OurCoffee/>}/>
            <Route path="/tea" element={<OurTea/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/shipping" element={<Shipping/>}/>
            <Route path="/products/:id" element={<ProductDetails/>}/>
            <Route path="/checkout" element={<Checkout/>}/>
            <Route path="*" element={<ErrorPage/>} />

          </Routes>
          
          <Footer />

        </Router>
      </div>
    </ProductsContextProvider>
  );
}

export default App;
