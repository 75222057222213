import {useContext} from 'react'
import { ProductsContext } from '../contexts/ProductsContext'
import BreadCrumpWrapper from "../components/BreadCrumpWrapper"
import RightImageSection from "../components/RightImageSection"
import LeftImageSection from "../components/LeftImageSection"
import "./OurTea.css"


const OurTea = () => {
  
  const {productsList} = useContext(ProductsContext)
  const teaProducts = productsList.filter(product =>product.category === "tea")
  return (
    <main>
      <article>
        <BreadCrumpWrapper pageTitle="Kirima Specialty Tea" pageUrl="Our Tea" id="tea-bread" />
        <div className="container our-tea-section">
            <h3 className="section-title h3">
                SUSTAINABLE FARMING
            </h3>
            <p className="p">Our Company is licensed by Tea Board of Kenya with packer license for packaging our tea brand “KIRIMA” specialty tea and Buyer & Exporter license to source and exporters’ bulk tea products for global markets. Our tea products supplies is from factories owned by small tea growers through “Kenya Tea Development Agency” (KTDA) and factories owned estates tea farmers all from highlands surrounding the slopes of Mount Kenya and Aberdare ranges. The tea leafs are harvested throughout the year.  At the factories, two methods of processing tea are employed. Where, CTC black tea is processed by fermentation method and Orthodox tea are processed by oxidization method without fermenting and are rolled as whole leaf. 
            <br />
            <br />
            Our company source, trade and exports the four primary grades that includes grade BP1, PF1, PD and Dust 1 to our global tea buyers. However, for our branded tea package, we blend and package grade BP1 for our local and exports markets. Our CTC black tea primary grades offers our tea lovers with their anticipated tastes and preference notwithstanding, rich body, good mouth feel & aftertaste, pleasing and appealing fragrance, aroma and distinctive flavours.  
            </p> 
           
        </div>
        <LeftImageSection 
              sectionTitle="QUALITY" 
              sectionText="Our tea is grown in the  tropical weather; fertile and minerals rich volcanic red soils and high altitudes topography in the Highlands around the slopes of aberdare ranges, easter and west slopes of mount Kenya. No chemicals aplied to control pest and diseases and our tea is susceptible to none.
              Our farmers plucks only two leafs and one bud after every seven to nine days and processing is done within twelve hours after plucking. Tea factories machines are modern, well maintained and staffs employed are professionals.
              Tea grades are sold fresh immediately after processing. Processing, handling, packaging, storage and transport hygiene are highly monitored."
              sectionImage="tea-farm-green.jpg"
              imageDescription="Green tea plantation."
            />
        <RightImageSection 
              sectionTitle="TRACEABILITY" 
              sectionText="
              For purpose of traceability, moment tea leaf is received at collection center; farmers delivered weights are recorded against their grower membership number record. Tea leaf collected every day from respective collection centers is delivered to the processing tea factory under their jurisdiction, weighted and recoded before processing. That daily, total tea leaf collection is consigned as a lot and issued with a unique patch number. This unique patch number is allocated to every grade extracted from the daily consignment after processing, during bagging accompanied with packaging date. This unique patch number is traceable all the way to the buyers’ warehouses and way backward to growers’ tea factory consignment processing information records.   "
              sectionImage="green-tea-field.png"
              imageDescription="Small scale holder farms"
            />
          <div className="container our-tea-section">
          <div style={{overflowX: "auto", marginBottom: "40px"}}>
            <table>
              <thead>
              <tr>
                <th>Product Name</th>
                <th>Product Type</th>
                <th>Grade</th>
              </tr>
              </thead>
              <tbody>
              {
                teaProducts.map(product=>{
                  return(
                    <tr key={product.productId}>
                      <td>Kirima Specialty Tea</td>
                      <td>{product.productName}</td>
                      <td>{product.grade}</td>
                    </tr>
                  )
                })
              }
              </tbody>
              </table>
          </div>
        </div>
      </article>
    </main>
  )
}

export default OurTea