import React from 'react'

const Logistics = () => {
  return (
    <section className="section offers">
        <div className="container">
            <p className="p">
            Mount Kenya Specialty Tea & Coffee Co. Ltd has the capacity supply any quantity of demand for green coffee beans either as Micro-lots and or full load, twenty foot container. Same applies CTC Black tea grades, including specialty tea types of Orthodox grades (green; Purple; white; black 0rthodox and instants tea). Both coffee and tea products are available throughout the year from January to December. 
            <br />
            <br />
            Our company applies incoterms for both coffee and tea with our esteemed customers a cross global markets. We listen to our customers and agree on most applicable terms depending on their country ease of trading and convenience. 
            </p>
        </div>
    </section>
  )
}

export default Logistics