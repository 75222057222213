import BreadCrumpWrapper from "../components/BreadCrumpWrapper"
import "./Contact.css"
import LetsTalk from "../components/homeComponents/LetsTalk"


const Contact = () => {
 
  return (
    <main>
      <article>
        <BreadCrumpWrapper pageTitle={"Let's talk over a cup of coffee/tea"} id="contact-bread" pageUrl="Contact" />
        <section className="section offers">
        <div className="container">
            <h3 className="section-title h3">
              VISIT US  
            </h3>
            <div className="visit-us">
              <div className="visit-us-text">
                <p className="p">Our company's head office location is in Kiambu County, about 30km/18.6miles from Jomo Kenyatta International Airport and  25km/15.5miles from Nairobi Central Business District. The company's physical address is at Ruiru Murera Sisal, Juja Sub-County, Theta ward. Along Ruiru-Kimbo road, off along Nairobi-Thika superhighway.              
                </p>
                <ul className="contact-details">

                  <li>
                    <p className="contact-list-title p">Contact Details</p>
                  </li>

                  <li className="contact-list-item p">
                    <div className="contact-icon">
                      <ion-icon name="call-outline"></ion-icon>
                    </div>

                    <a href="tel:+254723467903" className="contact-link">+254 723467903 </a>
                  </li>

                  <li className="contact-list-item p">
                    <div className="contact-icon">
                      <ion-icon name="mail-outline"></ion-icon>
                    </div>
                    

                    <a href="mailto:info@kenyaspecialtyteacoffee.co.ke" className="contact-link">info@kenyaspecialtyteacoffee.co.ke</a>
                  </li>
                  <li className="contact-list-item p">
                    <div className="contact-icon">
                      <ion-icon name="mail-outline"></ion-icon>
                    </div>
                    

                    <a href="mailto:mt.kenyaspecialtyteacoffee@gmail.com" className="contact-link">mt.kenyaspecialtyteacoffee@gmail.com </a>
                  </li>

                  <li className="contact-list-item p">
                    <div className="contact-icon">
                      <ion-icon name="logo-facebook"></ion-icon>
                    </div>

                    <a href="https://www.facebook.com/MountKenyaSpecialtyCoLtd" target="_blank" rel="noopener noreferrer">MountKenyaSpecialtyCoLtd </a>
                  </li>
                  <li className="contact-list-item p">
                    <div className="contact-icon">
                      <ion-icon name="logo-instagram"></ion-icon>
                    </div>

                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Mount Kenya Specialty CoLtd </a>
                  </li>
                  <li className="contact-list-item p">
                    <div className="contact-icon">
                      <ion-icon name="logo-youtube"></ion-icon>
                    </div>

                    <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">Mount Kenya Specialty </a>
                  </li>
                  
                 

                </ul>
              </div>
              <div className="visit-us-map">
                   <iframe title="Map location for company head offices" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24951.10780271206!2d36.96868185028376!3d-1.1507710834734795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x20167895734ef3cb!2zMcKwMDknMDkuOCJTIDM2wrA1OScwOC45IkU!5e0!3m2!1sen!2ske!4v1664464956087!5m2!1sen!2ske"  style={{border: "0", width: "100%", height: "300px"}}  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
       

            </div>
             
        </div>
    </section>
    <LetsTalk />
      </article>
    </main>
  )
}

export default Contact