import React, { useState, useContext, useEffect } from 'react'
import {Link} from "react-router-dom"
import logo from "./images/logo.png"
import {ProductsContext} from "./contexts/ProductsContext"
import "./Header.css"

const Header = () => {

  const [showNav, setShowNav] = useState(false)
  const {setShowCartPanel, cartList} = useContext(ProductsContext)
  const [floatNav, setFloatNav] = useState(false)


  const cartCount = cartList.length

  useEffect(() => {
      window.addEventListener('scroll', () => {
          if (window.scrollY > 100) {
            setFloatNav(true);
          } else {
            setFloatNav(false);
          }
      });
  }, []);

  // do I really need this goToTop here???
  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
    //hide card
  };
  const handleNavbarClick=()=>{
    setShowNav(false)
    goToTop()
  }
  const closeNavBar = ()=>{
    setShowNav(prevState=>!prevState)
    goToTop()
  }
  const openNavBar = ()=>{
    setShowNav(prevState=>!prevState)
  }
  
  return (
    <header className="header" data-header>

      <div className={floatNav? "top-bar bottom-margin" :"top-bar"}>
        <div className="container header-container">
          <div className="top-bar-item">
            <ion-icon name="location-outline" className="top-bar-icon" style={{display: "inline-block"}}></ion-icon>
            <a href="https://www.google.com/maps/place/1%C2%B009'09.8%22S+36%C2%B059'08.9%22E/@-1.3011848,36.8130913,10.24z/data=!4m5!3m4!1s0x0:0xa25ecd336418e8ce!8m2!3d-1.1527136!4d36.9858165?hl=en" target="_blank" rel="noopener noreferrer" style={{display: "inline-block"}}> Ruiru, Kenya</a>
          </div>
          <div className="top-bar-item socials">
            <a href="https://www.facebook.com/MountKenyaSpecialtyCoLtd" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-facebook"></ion-icon></a>
            <a href="https://wa.me/254723467903" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-whatsapp"></ion-icon></a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-youtube"></ion-icon></a>
          </div>
        </div>
      </div>

      <div className={floatNav? "nav-wrapper sticky-nav": "nav-wrapper"}>
        <div className="container">

        

          <button onClick={openNavBar} className="nav-open-btn" aria-label="Open Menu" data-nav-open-btn>
            <ion-icon name="menu-outline"></ion-icon>
          </button>
          <h1 className="h1">
            <Link to="/" className="logo company-brand">
              <img src={logo} alt="Company Logo" style={{height: "70px"}}/>
              <div className="only-small company-title">
                MOUNT KENYA <br/> <span className="span">Specialty </span> tea & <span className="span">coffee</span>
              </div>
            </Link>
          </h1>

          <nav className={showNav? "navbar active": "navbar"} >

            <button onClick={closeNavBar} className="nav-close-btn" aria-label="Close Menu" data-nav-close-btn>
              <ion-icon name="close-outline"></ion-icon>
            </button>

            <ul className="navbar-list">

              <li>
                <Link to="/" onClick={handleNavbarClick} className="navbar-link">Home</Link>
              </li>

              <li>
                <Link to="/about" onClick={handleNavbarClick} className="navbar-link">About Us</Link>
              </li>

              <li>
                <Link to="/coffee" onClick={handleNavbarClick} className="navbar-link">Our Coffee</Link>
              </li>

              <li>
                <Link to="/tea" onClick={handleNavbarClick} className="navbar-link">Our Tea</Link>
              </li>

              <li>
                <Link to="/shipping" onClick={handleNavbarClick} className="navbar-link">Shipping & Logistics</Link>
              </li>

              <li>
                <Link to="/contact" onClick={handleNavbarClick} className="navbar-link">Contact us</Link>
              </li>
            </ul>

          </nav>

          <div className="header-action">
            

            <button onClick={()=>setShowCartPanel(true)}  className="header-action-btn" aria-label="Open shopping cart" data-panel-btn="cart">
              <ion-icon name="basket-outline"></ion-icon>

              <data className="btn-badge" value={cartCount}>{cartCount}</data>
            </button>

          </div>

        </div>
      </div>

    </header>
  )
}

export default Header