import React from 'react'

const Testimonials = () => {
  return (
    <section className="section testimonials">
        <div className="container">

          <p className="section-subtitle"> -- True story: We put our faith in every cup --</p>

          <h2 className="h2 section-title">Feedback From Happy Clients</h2>

          <ul className="has-scrollbar">

            <li className="testi-item">
              <div className="testi-card">

                <div className="card-header">

                  <img src="./assets/images/quote-left.png" width="25" height="25" aria-hidden="true" alt=""/>

                  <img src="./assets/images/testimonial-1.jpg" width="90" height="90" loading="lazy"
                    alt="Willow Peralta" className="card-avatar"/>

                  <img src="./assets/images/quote-right.png" width="25" height="25" aria-hidden="true" alt=""/>

                </div>


                <blockquote className="card-text">
                  It was popularised in the 1960s
                  with the release of Letraset sheets
                  containing Lorem passages and more recently with desktop publishing software like including.
                </blockquote>

                <h3 className="card-title">Anne Peralta</h3>

                <p className="card-subtitle">Coffee Taster</p>

              </div>
            </li>

            <li className="testi-item">
              <div className="testi-card">

                <div className="card-header">

                  <img src="./assets/images/quote-left.png" width="25" height="25" aria-hidden="true" alt=""/>

                  <img src="./assets/images/testimonial-2.jpg" width="90" height="90" loading="lazy"
                    alt="Pamelia Hamrick" className="card-avatar"/>

                  <img src="./assets/images/quote-right.png" width="25" height="25" aria-hidden="true" alt=""/>

                </div>


                <blockquote className="card-text">
                  It was popularised in the 1960s
                  with the release of Letraset sheets
                  containing Lorem passages and more recently with desktop publishing software like including.
                </blockquote>

                <h3 className="card-title"> John Doe</h3>

                <p className="card-subtitle">Restaurant Manager</p>

              </div>
            </li>

            <li className="testi-item">
              <div className="testi-card">

                <div className="card-header">

                  <img src="./assets/images/quote-left.png" width="25" height="25" aria-hidden="true" alt=""/>

                  <img src="./assets/images/testimonial-3.jpg" width="90" height="90" loading="lazy"
                    alt="Novella Gerber" className="card-avatar"/>

                  <img src="./assets/images/quote-right.png" width="25" height="25" aria-hidden="true" alt=""/>

                </div>

                

                <blockquote className="card-text">
                  It was popularised in the 1960s
                  with the release of Letraset sheets
                  containing Lorem passages and more recently with desktop publishing software like including.
                </blockquote>

                <h3 className="card-title">Peter Drury</h3>

                <p className="card-subtitle">Retail Shop Owner</p>

              </div>
            </li>

          </ul>

        </div>
      </section>
  )
}

export default Testimonials