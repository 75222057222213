
const Intro = () => {
  return (
    <section className="section offers">
        <div className="container">
            <h3 className="section-title h3">
            BACKGROUND INFORMATION  
            </h3>
            <p className="p">Mount Kenya Specialty Tea & Coffee is a private, limited company; family owned and was incorporated in 29th June 2016. The company is licensed by AFA-Coffee Directorate to trade and exports green coffee beans; coffee roasting for both exports and domestic consumptions. The company is also licensed by the Tea Board of Kenya, as tea packer and tea buyer and exporter.             
            <br />
            <br />
            We are member of Kenya National Chamber of Commerce & Industrial (KNCCI), in 2022 we have made resolution to join Kenya Private Sector Alliance (KEPSA) and Kenya Association of Manufacturers. We also collaborate in partnership with coffee co-operatives and small & medium coffee estates, including commercial and private grower coffee millers. In tea sector we work very closely with Kenya Tea Development Agency tea factories, including private tea estates growers and processors. 
            <br />
            <br />
            Mount Kenya Specialty Tea & Coffee Co. Ltd is an equal opportunity employer, with a lot of emphasis to youth and women. We promote and participate in coffee and tea farmers capacity building with to develop good governance, high quality production and continuous increments to production quantity. Hence, with the right partners, we ensure better and sustainable market resulting in earnings that guarantee imporved living standards. We aim at eradicating poverty and creating wealth, that assures a life with dignity to farmers and their households, at the same time contributing towards growth of national GDP.
            </p> 
        </div>
    </section>
  )
}

export default Intro


