import {useContext} from 'react'
import {ProductsContext} from "../contexts/ProductsContext"
import BreadCrumpWrapper from "../components/BreadCrumpWrapper"
import RightImageSection from "../components/RightImageSection"
import LeftImageSection from "../components/LeftImageSection"
import "./OurCoffee.css"

const OurCoffee = () => {
  const {productsList} = useContext(ProductsContext)
  const coffeeProducts = productsList.filter(product =>product.category === "coffee")
  return (
    <main>
      <article>
        <BreadCrumpWrapper pageTitle="Kirima Specialty Coffee" pageUrl="Our Coffee" id="coffee-bread" />
        <div className="container our-coffee-section">
            <h3 className="section-title h3">
                SUSTAINABLE FARMING
            </h3>
            <p className="p">Our coffee growers practice sustainable farming with commitment to climate change mitigation. They practice afro-forestry by inter-cropping coffee bushes with trees, as wind-breakers; avocado, macadamia and banana as shed-trees. They practice integrated farming to preserve soil and water, responsible use of pests and diseases control to minimize effects of chemical residue to consumers and environments.
            <br />
             Our farmers’ sources their inputs from licensed agroinputs supply chains and keep records of inputs bought and used. The farmers’ works in collaboration with Coffee Research Institute and other licensed organization by the Government for their soil and leaf analysis and are advised appropriately on nutrition, pests and diseases control measures to undertake. 
            </p> 
           
        </div>
        <LeftImageSection 
              sectionTitle="BEANS & CUP QUALITY" 
              sectionText="Our coffee is of high specialty & premium quality with unique quality attributes; related with fragrance, aroma tastes and preferences associated to specialty coffee niche markets. This is contributed by tropical weather; fertile and minerals rich volcanic red soils and topography in the Highlands around the slopes of aberdare ranges and mount Kenya. Other factors contributing is efforts of our farmers through good agricultural and processing practices. These practices include;  canopy management, nutrition, diseases and pests control, harvesting oly over 90% ripe cherry, separation of under & over ripe and diseased cherry from red ripe cherry, pulp within eight hours after harvest, critical monitoring during fermentation, drying and storage. Including professional dry coffee milling and safe transitions from wet mills  and to warehouses in readiness for sales.
              We evaluate our quality based on SCA cupping protocol and share our reports with our buyers."
              sectionImage="ceo-quality-tasting.jpg"
              imageDescription = "Tasting a specialty coffee sample to evaluate quality attributes and qualifying the cups' score(%). "
            />
        <div className="container our-coffee-section">
            <h3 className="section-title h3">
                NATURAL SOLAR DRYING
            </h3>
            <p className="p">
              All our specialty green coffee beans grades from fully washed, Natural dried and Honey processed methods are dried under sum solar on raised sun dry tables, without re-wetting. In summary, no single bean is mechanically dried.
            </p> 
           
        </div>
        
        <RightImageSection 
              sectionTitle="TRACEABILITY" 
              sectionText="Our coffee beans are traceable from tree to cup. Coffee Cherry delivered at the wet mills, sorted, pulped, fermented, washed and separated in various parchment grades and dried as lots. Every lot of parchment grades consignment are delivered to the dry coffee mills for hulling and grades separations are issued with outturn number which is unique. The unique outturn number helps with traceability of every lot of coffee green beans grades extracted from every parchment grades consignment lot delivered. The unique outturn number for every grade is traceable up to coffee roasters warehouse and traceable backwards to the producer(s)."
              sectionImage="sun-drying.jpg"
              imageDescription="Sun drying washed specialty coffee beans."
            />
        <LeftImageSection 
              sectionTitle="" 
              sectionText="For purpose of tracing our branded roasted coffee, and or private label package from roastry to consumers. Every green coffee beans lot’s received and stocked in our warehouse are issued with an inventory patch number, time and date received. The same patch number together with manufacturing and expiry dates are printed on the packaging materials, during roasting and packaging processes. This packaging information’s during invoicing and payment, logistics, products recall, products’ returns and refunds in case of any customer complains."
              sectionImage="hand-picking.jpg"
              imageDescription="A farmer hand picking coffee beans."
            />
        <div className="container our-coffee-section">
          <div style={{overflowX: "auto", marginBottom: "40px"}}>
            <table>
              <thead>
              <tr>
                <th>Product Name</th>
                <th>Product Type</th>
                <th>Grade</th>
                <th>Quality SCA-Cup Score</th>
              </tr>
              </thead>
              <tbody>
              {
                coffeeProducts.map(product=>{
                  return(
                    <tr key={product.productId}>
                      <td>Kirima Specialty Coffee</td>
                      <td>{product.productName}</td>
                      <td>{product.grade}</td>
                      <td>{product.qualityCupScore}</td>
                    </tr>
                  )
                })
              }
              </tbody>
              </table>
          </div>
        </div>
      </article>
    </main>
  )
}

export default OurCoffee