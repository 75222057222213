import React from 'react'
import "./Team.css"

const Team = () => {
  return (
    <section className="section offers">
    <div className="container">
        <h3 className="section-title h3">
        OUR TEAM  
        </h3>
        <p className="p">
            <br />
        The CEO & Founder is a seasoned agricultural Engineer, specialized in farm outputs processing, with career development in coffee value chain. He has over 21 years experience in farm Good Agricultural & Processing Practices, coffee wet & dry milling, marketing, warehousing, logistics & shipping. <strong>Simon Gakinya</strong>, BSc.Hon. Agricultural Engineering; Specialist in "Farm Outputs Processing"; MBA Strategic Management; Trained and licenced coffee Taster and a Marketing lead expert, is the coffee and tea value chain lead expert for your cup. 
        <br />
         Since August 1999, he has gathered a plethora of skills and experiences having worked with Farmer's Co-operative Societies, small to medium coffee estates and large coffee estates. In addition he is a trained Q-grader with professional coffee sensory skills, green coffee modules and professional coffee roasting expertise. He is highly regarded and respected by many tea and coffee growers in Kenya. Over time he has established a good working relationship with coffee value chain stakeholders and governments agencies. 
        <br />
        <br /> 
        </p>
        
        <ul className="has-scrollbar " id="team-list">
            

            <li className="testi-item">
            <div className="testi-card team-card">

                <div className="card-header">

                <img src="./assets/images/director-founder.jfif" loading="lazy"
                    alt="SIMON KANIARU GAKINYA" className="card-avatar"/>

                </div>
                <h3 className="card-title">SIMON KANIARU GAKINYA </h3>

                <p className="card-subtitle" style={{fontWeight: 'bolder', fontSize: "1.6rem"}}> Managing Director & Founder.  </p> 
                
                <p className="card-subtitle">
                   BSc.Hon. Agricultural Engineering, MBA Strategic Management. </p>
                
                <blockquote className="card-text">
                    "I am passionate in quality from good agricultural practices and good processing practices that resonate with customer's taste, preference and safety; in traceability and sustainable farming with climate changes mitigation priority. I am a crusader of sustainable markets that yield quality income and wages to coffee and tea producers. Our business is also arched to Responsible Consumption and Production of the United Nations sustainable Development Goals."
                </blockquote>

                

            </div>
            </li>
            <li className="testi-item">
            <div className="testi-card team-card">

                <div className="card-header">

                <img src="./assets/images/finance-hr.jpg" loading="lazy"
                    alt="MS. TABITHA NJAMBI GITHUI" className="card-avatar"/>

                </div>

                <h3 className="card-title">MS. TABITHA NJAMBI GITHUI </h3>

                <p className="card-subtitle" style={{fontWeight: 'bolder', fontSize: "1.6rem"}}>Director: Finance and Human Resources Administration.</p>
                <p className="card-subtitle">B.A. Maths & Economics and Accounting</p>

                
                <blockquote className="card-text">
                "We value sustainable business as Mount Kenya Specialty Tea & Coffee Co Ltd and more so  we thrive when we supply our buyers and clients with affordable, reliable and high quality specialty Tea & Coffee that fortify their consumers' loyalty, business growth and development. We give equal opportunities especially to the physically challenged, youth and women, working together to eradicate poverty through sustainable income and wages to create wealth."
                </blockquote>

                

            </div>
            </li>
            <li className="testi-item">
            <div className="testi-card team-card">

                <div className="card-header">

                <img src="./assets/images/communications.jpg" loading="lazy"
                    alt="MS. MICHELLE KANIARU" className="card-avatar"/>

                </div>

                <h3 className="card-title">MS. MICHELLE KANIARU</h3>

                <p className="card-subtitle" style={{fontWeight: 'bolder', fontSize: "1.6rem"}} >Director: Strategic Sales, Marketing  and Customer Relations.</p>
                <p className="card-subtitle">Bachelor of Communication and Journalism </p>

                
                <blockquote className="card-text">
                "My utmost priority is to ensure that our business is customer driven,for both local and global markets so as to create a win-win environment. We highly value our customer's feedback and aspire to meet values based on health & safety, monetary and taste preferences. Our focus on specialty tea and coffee, traceability and sustainable farming places us at a position to fulfill our promise as embodied in our business tagline: -Buy quality, Earn your reward"
                </blockquote>

               

            </div>
            </li>
            <li className="testi-item">
            <div className="testi-card team-card">

                <div className="card-header">

                <img src="./assets/images/logistics-shipping-manager.jpg" loading="lazy"
                    alt="JACKSON M KARANJA" className="card-avatar"/>

                </div>

                <h3 className="card-title">JACKSON M KARANJA </h3>

                <p className="card-subtitle" style={{fontWeight: 'bolder', fontSize: "1.6rem"}} >General Manager: Warehouse, Logistics and Shipping. </p>
                <p className="card-subtitle">Bachelor's Degree in Procurement & Logistics </p>

                
                <blockquote className="card-text">
                A Full Member of Kenya Institute Supplies Management (K.I.S.M), Licensed Supply Chain management Practitioner and Associate Member of The Chartered institute of Procurement and Supply( C.I.P.S)-UK. He has extensive work experience in Sourcing, Logistics(Warehouse receipt system, Shipping & Distribution) and import & Export of Agricultural commodities - Coffee, Tea, Macadamia, Avocado, cocoa, soya beans, ground nuts and sesame. 
                </blockquote>

               

            </div>
            </li>
           
           
        

            </ul>
    </div>
    </section>
  )
}

export default Team